import * as _shams2 from "has-tostringtag/shams";

var _shams = "default" in _shams2 ? _shams2.default : _shams2;

import * as _callBound2 from "call-bind/callBound";

var _callBound = "default" in _callBound2 ? _callBound2.default : _callBound2;

var exports = {};

var hasToStringTag = _shams();

var callBound = _callBound;
var $toString = callBound("Object.prototype.toString");

var isStandardArguments = function isArguments(value) {
  if (hasToStringTag && value && typeof value === "object" && Symbol.toStringTag in value) {
    return false;
  }

  return $toString(value) === "[object Arguments]";
};

var isLegacyArguments = function isArguments(value) {
  if (isStandardArguments(value)) {
    return true;
  }

  return value !== null && typeof value === "object" && typeof value.length === "number" && value.length >= 0 && $toString(value) !== "[object Array]" && $toString(value.callee) === "[object Function]";
};

var supportsStandardArguments = function () {
  return isStandardArguments(arguments);
}();

isStandardArguments.isLegacyArguments = isLegacyArguments; // for tests

exports = supportsStandardArguments ? isStandardArguments : isLegacyArguments;
export default exports;